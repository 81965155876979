import React, {memo} from 'react'
import {createGlobalStyle} from "styled-components";
// import {maxCol} from "../../function/SizeCol";
import Light from '../fonts/Geologica-Light.woff2';
import Regular from '../fonts/Geologica-Regular.woff2';
import SemiBold from '../fonts/Geologica-SemiBold.woff2';
import ExtraBold from '../fonts/Geologica-ExtraBold.woff2';
import Black from '../fonts/Geologica-Black.woff2';


const GlobalFonts = createGlobalStyle`
    @font-face {
        font-family: 'Geologica';
        font-style: normal; 
        font-weight: 300; 
        src: url("${Light}") format("woff2");
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        font-optical-sizing: auto;
    }
    @font-face {
        font-family: 'Geologica';
        font-style: normal;
        font-weight: 400;
        src: url("${Regular}") format("woff2");
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        font-optical-sizing: auto;
    }
    @font-face {
        font-family: 'Geologica';
        font-style: normal;
        font-weight: 600;
        src: url("${SemiBold}") format("woff2");
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        font-optical-sizing: auto;
    }
    @font-face {
        font-family: 'Geologica';
        font-style: normal;
        font-weight: 800;
        src: url("${ExtraBold}") format("woff2");
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        font-optical-sizing: auto;
    }

    @font-face {
        font-family: 'Geologica';
        font-style: normal; 
        font-weight: 900;
        src: url("${Black}") format("woff2");
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        font-optical-sizing: auto; 
    }
`;





const GlobalFontsWrap  = memo(() => {
    // console.log('>>')
    return <GlobalFonts />
})

export default GlobalFontsWrap;
// export default GlobalFonts;