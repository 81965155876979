import { createGlobalStyle } from 'styled-components'
import {minCol,maxCol} from "../../function/SizeCol"
import Icons24_32 from "../svg/24/Icons24px-32.svg"
import Icons24_31 from "../svg/24/Icons24px-31.svg"

const GlobalStyles = createGlobalStyle`

    :root {
        --dark: #020404; // MF Dark
        --gray: #363738; // MF Gray
        --gray_op4: #171818; // MF Gray
        --lgray: #828284; // MF Light Gray
        --slgray: #DFDED7; // MF Super Light Gray
        --paper: #F2F1E8; // MF Paper
        --red : #E32839; //MF Red
        --green: #00D8A9; // MF Green
        --blue: #00A0E2; // MF Blue 
        --yellow: #FFA400 // MF Yellow
    }
    
.ovh {
    overflow: hidden;
}
img {   
    max-width: 100%;
} 
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
// #86644B
/* Track */
::-webkit-scrollbar-track {
  background-color: var(--slgray);  
} 
 
/* Handle */ 
::-webkit-scrollbar-thumb {
    background: var(--blue);
    //background: var(--lgray);
}

html, body {
    margin: 0;
    padding: 0;
}

* {
  outline: none;
}

strong {
   font-weight: 800;
}

html {
    //font-size: 50%;
    --bs-gutter-x: 2rem;
    font-size: 62.5%;
    @media (min-width: ${minCol.sm} ) { 

    }
    
    @media (min-width: ${minCol.md} ) {
        
    }
    
    @media (min-width: ${minCol.lg} ) {

    }
    
    @media (min-width: ${minCol.xl}) {
        
    }
     
    @media (min-width: ${minCol.xxl}) {
        
    } 
}
 
body {
    font-family: Geologica;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.25;
    text-underline-position: from-font; 
    text-decoration-skip-ink: none;
    color: #363738;
    background-color: var(--paper);
}

.body.colorRevers {
    color: var(--paper);
    a { 
        color: var(--paper);
    }
    background-color: var(--dark);
    .btn.btn1 {
        background-color: rgba(0,0,0,0);
        .iconBG:before {
            filter: brightness(0) invert(1); 
        }
    }
    .border-hor:before, .border-hor:after, .border-ver:before, .border-ver:after {
        background-color: var(--paper);
    }
    .row-section>* {
        border-color: var(--paper);
    }
}
.template-page404 {
    height: 100vh;
    .container, .wrapMain, .main {
        height: 100%;
    }
    .wrapMain { 
        display: flex;
        flex-direction: column;
    }
    .main {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 1.8rem;
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: -2000px;
            right: -2000px;
            background-color: var(--paper);
            height: 2px;
            bottom: 1.8rem;
            top: auto;
        }
    }
}    
 
.wrapContainer {
    max-width: 90rem;
    margin-left: auto;
    margin-right: auto;
}
 

// Fonts ************************************

.fw-3, .st-3, .st-4 { font-weight: 300;}
.fw-4 { font-weight: 400;}
.fw-6, .h4, .h5 { font-weight: 600;}
.fw-8, strong, .h1, .h2, .h3 { font-weight: 800;}
.fw-9 { font-weight: 900; }

h1,h2,h3,h4,h5,h6 {
    margin: 0;
}

// Heading
.h1 { font-size: 5.8rem; line-height: 1;}
.h2 { 
    font-size: 4.8rem;
    @media (max-width: ${maxCol.sm}) {
        font-size: 4.2rem;
    }
}
.h3 { 
    font-size: 3.2rem;
    @media (max-width: ${maxCol.sm}) {
        font-size: 2.4rem;
    }
}
.h4 { font-size: 2.8rem;} 
.h5 { font-size: 2.4rem;}

.h2,.h3,.h4,.h5  {
    line-height: 1.25;
}

// Subtitle
.st1 { font-size: 2rem; line-height: 1.1;} 
.st2 { font-size: 1.8rem; } 
.st3 { font-size: 1.6rem; }  // Body Text 
.st4 { font-size: 1.4rem;} 
.st5 { font-size: 1.2rem; }  // Note

.st2,.st3,.st4,.st5  { 
    line-height: 1.25;
}

// color ************************************



.box {
    width: 20rem;
    height: 7.5rem;
    color: #fff;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bgc1 { background-color: var(--dark); }
.bgc2 { background-color: var(--gray); }
.bgc3 { background-color: var(--lgray); }
.bgc4 { background-color: var(--slgray); }
.bgc5 { background-color: var(--paper); }
.bgc6 { background-color: var(--red); }
.bgc7 { background-color: var(--green); }
.bgc8 { background-color: var(--blue); }
.bgc9 { background-color: var(--yellow); }
 
.tc1 { color: var(--dark); }
.tc2 { color: var(--gray); }
.tc3 { color: var(--lgray); }
.tc4 { color: var(--slgray); }
.tc5 { color: var(--paper); }
.tc6 { color: var(--red); }
.tc7 { color: var(--green); }
.tc8 { color: var(--blue); }
.tc9 { color: var(--yellow); }

// Badges ************************************

.bds {
    font-size: 1.2rem;
    line-height: 1; 
    text-align: center;
    text-transform: uppercase; 
    padding: 0.8rem 2.45rem; 
    border-radius: 5.5rem;
    display: inline-flex;
    color: var(--paper);
    &.min {
        padding: 0.5rem 1.25rem;
    }
}

// Icons ************************************

.icon24:before {
    width: 2.4rem;
    height: 2.4rem;
    display: inline-flex;
}
.icon18:before {
    width: 1.8rem;
    height: 1.8rem;
    display: inline-flex;
}
.iconBG {
    display: flex;
    align-items: center;
    &.iconWhite {
        filter: brightness(0) invert(1);
    }
}
.iconBG:before {
    display: inline-flex;
    content:'';
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    //margin-right: 2rem;
    z-index: 2;
}

 

// Buttons ************************************

.btn {
    //margin: 0.4rem;
    display: inline-flex;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 1;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    span {
        z-index: 2;
    }
    &:after {
        content: '';
        position: absolute;
        margin: auto;
        z-index: 1;
        display: block;
        top: -200%;
    }
    &.btn1 {
        border: 2px solid var(--gray);
        padding: 1.4rem 7rem;
        background-color: var(--paper);
        font-size: 1.6rem;
        color: var(--dark);
        &:after {
            left: 0;
            right: 0;
            bottom: 0;
            width: calc(100% - 0.4rem);
            height: calc(100% - 0.4rem);
            background-color: var(--blue);
            border: 0.2rem solid rgba(0,0,0,0);
        }
        &:hover, &.pressed {
            &:after {
                top: 0;
            }
        }
        &.select, &[aria-current="page"] {
            &:after {
                top: 0;
                background: none;
                border: 0.2rem solid var(--blue);
            }
        }
        &.disable { 
            border-color: var(--lgray);
            color: var(--lgray);
            cursor: default;
            &:after {
                display: none;
            }
        }
    } 
    &.btn2 {
        //border: 2px solid var(--gray);
        border: none;
        padding: 1.6rem 7.2rem;
        background-color: var(--gray);
        color: var(--paper);
        font-size: 1.6rem;
        &:after {
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255,255,255,0.2);
        } 
        &:hover, &.pressed {
            &:after {
                top: 0;
            }
        }
        &.select, &[aria-current="page"] {
            &:after {
                left: 0;
                top: 0;
                width: calc(100% - 0.8rem);
                height: calc(100% - 0.8rem);
                background: none;
                border: 0.2rem solid var(--blue);
            }
        }
        &.disable {
            border-color: var(--slgray); 
            background-color: var(--slgray);
            color: var(--lgray);
            cursor: default; 
            &:after {
                display: none;
            }
        }
    }
    &.btn3 {
        //border: 2px solid var(--gray);
        border: none;
        padding: 1.6rem 7.2rem;
        background-color: var(--blue);
        color: var(--paper);
        font-size: 1.6rem;
        &:after {
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255,255,255,0.2);
        }
        &:hover, &.pressed {
            &:after {
                top: 0;
            }
        }
        &.select, &[aria-current="page"] {
            &:after {
                left: 0;
                top: 0;
                width: calc(100% - 0.8rem);
                height: calc(100% - 0.8rem);
                background: none;
                border: 0.2rem solid var(--paper);
            }
        }
    }
    &.btn4 { 
        background-color: var(--slgray);
        border-radius: 3.2rem;
        display: inline-flex;
        align-items: center;
        font-weight: 400;
        text-transform: none;
        padding: 0.85rem 2.4rem 0.85rem 2.4rem;
        .iconBG {
            margin-left: 0.8rem;
        }
        &:hover {
            background-color: var(--blue);
        }
    }

    &.btn5 {
        display: inline-flex;
        align-items: center;
        text-transform: none;
        //padding: 1.2rem 2.4rem 1.2rem 2.4rem;
        font-weight: 400;
        .iconBG {
            margin-left: 1.2rem;
        }
        &:hover { 
            color: var(--blue);
        }
    }
    &.btn6 {
        //border: 2px solid var(--gray);
        border: none;
        padding: 1.6rem 7.2rem;
        background-color: var(--dark);
        color: var(--paper);
        font-size: 1.6rem;
        &:after {
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255,255,255,0.2);
        }
        &:hover, &.pressed {
            &:after {
                top: 0;
            }
        }
        &.select, &[aria-current="page"] {
            &:after {
                left: 0;
                top: 0;
                width: calc(100% - 0.8rem);
                height: calc(100% - 0.8rem);
                background: none;
                border: 0.2rem solid var(--blue);
            }
        }
        &.disable {
            border-color: var(--slgray);
            background-color: var(--slgray);
            color: var(--lgray);
            cursor: default;
            &:after {
                display: none;
            }
        }
    }


    &.btnIcon {
        width: 5.6rem;
        height: 5.6rem;
        padding: 0;
        .iconBG {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
        }
        &.disable {
            .iconBG {
                opacity: 0.6;
            }
        }
    }
    
    &.btnIconSquare {
        .iconBG {
            border-radius: 50%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 4.6rem;
            height: 4.6rem;
            opacity: 1;
        }
        &:hover, &.pressed {
            .iconBG {
                background-color: var(--blue);
                &:before {
                    filter: brightness(0) invert(1);
                }
            }
            
        }
    }
    &.borderNone {
        border: none;
    }
    &.bgNone {
       background: none;
    }
    
    &.cursorNone {
        cursor: default;
    }
     
}


// form input  ************************************
 

.wrap-form {
    .formTitle {
        &.style-1 {
            color: var(--blue);
            margin-bottom: 2rem;
            line-height: 1.25;
        }
    }
}
.wrap-input {
    margin-bottom: 1.6rem;
    label {
        font-size: 1.4rem;
        font-weight: 300;
        line-height: 1;
        text-align: left;
        display: block;
        color: var(--lgray);
        margin-bottom: 1.4rem;
    }
    input, textarea {
        display: block;
        font-size: 1.6rem;
        line-height: 1.25;
        background-color: var(--slgray);
        color: var(--gray);
        border: 2px solid var(--slgray);
        width: 100%;
        height: 5.6rem;
        padding: 0 2.4rem; 
        &::placeholder {
            color: var(--lgray); 
        }
        &:active, &:focus {
            border: 2px solid var(--blue);
        }
    }
    &.error {
        label {
            color: var(--red);
        }
        input {
            border-color: var(--red);   
        }
    }

    textarea {
        height: 11.2rem;
        padding: 2.4rem;
    }
    
    &.searchBox {
        position: relative;
        border: 2px solid var(--lgray);
        display: flex;
        align-items: center;
        .btn {
            margin: 0;
            min-width: 5.8rem;
        }
        .iconBG:before {
            filter: brightness(0) invert(1);
        }

        .btn {
            border-left: 2px solid var(--lgray);
        }
        
        &.black { 
            input {
                background-color: var(--gray_op4);
                border-color: var(--gray_op4);
                color: var(--paper);
            } 
            border: 2px solid var(--gray);
            .btn {
                border-left: 2px solid var(--gray);
                background-color: var(--dark);
            }
        }
    }
}

// link  ************************************


a {
    color: inherit;
    &:hover {
        color: var(--blue);
    }
}

.link {
    cursor: pointer;
    text-transform: none;
    &.link-1, &.link-2 {
        width: fit-content;
        position: relative;
        overflow: hidden;
        display: flex;
        padding-bottom: 1rem;
        &:after, &:before {
            content: "";
            display: block;
            position: absolute;
            height: 2px;
            background-color: var(--gray);
            bottom: 0rem;
        }
        &:before {
            transform: translate3d(-102%, 0, 0);
            transition: 1000ms transform cubic-bezier(0.23, 1, 0.32, 1);
        }
        &:after {
            transition: 1000ms transform cubic-bezier(0.23, 1, 0.32, 1) 200ms;
        }
    
        &:hover {
            &:before {
                transform: translate3d(0%, 0, 0);
                transition: 1000ms transform cubic-bezier(0.23, 1, 0.32, 1) 200ms;
            }
            &:after {
                transform: translate3d(calc(101% + 20px), 0, 0);
                transition: 1000ms transform cubic-bezier(0.23, 1, 0.32, 1);
            }
        }
    }
    
    &.link-1 {
        &:after, &:before {
            width: 50%;
        }
        &:after {
            display: none;
        }
    }
    &.link-2 {
        &:after, &:before {
            width: 100%;
        }
        &:before {
            display: none;
        }
        &:hover {
            &:after {
                transform: translate3d(calc(30%), 0, 0);
                transition: 1000ms transform cubic-bezier(0.23, 1, 0.32, 1);
            }
        }
    }
    &.link-3 {
        color: inherit;
        &:hover {
            color: var(--blue);
        }
    }
}

// css  ************************************
 
.anim, a, .a, .btn, .btn:before, .btn:after, .iconBG, .iconBG:before, .link, input, textarea {
    transition: all 0.5s ease;
    //transition: 1000ms transform cubic-bezier(0.23, 1, 0.32, 1) 200ms;
    transition: 500ms all 200ms; 
}

p {
    margin: 0 0 2rem;
}

.grey {
    //filter: grayscale(100%);
}
.grey_off {
    filter: none;
}
.pos {
    position: relative;
}
.z-in-1 {
    z-index: 1;
}
.z-in-2 {
    z-index: 2;
}
.h100 {
    height: 100%;
}
.h50 {
    height: 50%;
}
.mh100 {
    min-height: 100%;
}
.mh50 {
    min-height: 50%;
}
.w100 {
    width: 100%;
}
.ul-clear {
    margin: 0;
    padding: 0;
}
.ul-clear li::marker {
    font-size: 0;
}
.p-0 {
    padding:0;
}
.pr-0 {
    padding-right: 0 !important;
}
.pl-0 {
    padding-right: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}
.pb-0 {
    padding-bottom: 0 !important;
}

.m-0 {
    margin:0;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.body {
    overflow: hidden;
}
.ttu {
    text-transform: uppercase;
}

 
// border-line-block  ************************************

.row-0 {
    --bs-gutter-x: 0 !important;
}
.row-section {
    & > * {
        border-right: 2px solid var(--gray);
        &:last-child {
            border-right: 2px solid rgba(0,0,0,0);
        }
    }
    &.color-white {
        & > * {
            border-right: 2px solid var(--paper);
        }
    }
}

// wrapMain

.wrapMain {
    min-height: 50vh; 
}

.border-ver {
    position: relative;
    &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        top: -1000px;
        bottom: 0px;
        left: 0;
        background-color: var(--gray);
        width: 2px;
    }
    &:after {
        left: auto; 
        right: 0;
    }
    &.color-white {
        &:before, &:after {
            background-color: var(--paper);
        }
    }
}
 
.border-hor {
    position: relative;
    padding-top: 2px;
    padding-bottom: 2px;
    &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        left: -2000px; 
        right: -2000px;
        background-color: var(--gray);
        height: 2px;
        top: 0;
    }
    &:after {
         bottom: 0;
        top: auto;
    }
    &.color-white {
        &:before, &:after {
            background-color: var(--paper);
        }
    }
}

.border-hor-bottom {
    padding-bottom: 2px;
} 
.border-hor-top {
    padding-top: 2px;
}
.border-hor-bottom, .border-hor-top {
    position: relative;
    &:after {
        content: '';
        display: block;
        position: absolute;
        left: -2000px;
        right: -2000px;
        background-color: var(--gray);
        height: 2px;
        top: 0;
    }
    &:after {
        bottom: 0;
        top: auto;
    }
    &.color-white {
        &:after {
            background-color: var(--paper);
        }
    }
}

.border-hor-top {
    &:after {
        bottom: auto;
        top: 0;
    }
}



// menu ************************************
 
.wrapMenu {
    [aria-current="page"] {
        color: var(--blue);
    }
    ul {
        margin: 0;
        padding: 0;
    }
    a {
        text-decoration: none;
    }
    ::marker {
        font-size: 0;
    }
    &.style-1 {
        height: 100%;
        padding: 0 4.8rem;
        li {
            padding-right: 4.2rem;
        }
        @media (max-width:${maxCol.xl}) {
            padding: 0 2.4rem; 
            li {
                padding-right: 2.1rem;
            }
        }
    }
    &.style-2 {
        button {
            width: 100%;
            background: none;
            border: none;
            color: var(--paper);
            text-align: left;
            font-size: 1.6rem;
            padding-left: 0;
        }
        li {
            margin-bottom: 3.2rem;
        }
        .ul-sub {
            position: absolute;
            top: 2rem;
            width: calc(100% - 4rem);
            left: 2rem;
            bottom: 2rem;
            z-index: 1;
            padding-top: 5rem;
            background-color: var(--dark);
            &.open {
                left: 2rem;
            }
            &.close {
                left: -200%;
            }
        }
        .a {
            display: block;
            position: relative;
            min-height: 2.4rem;
            cursor: pointer;
            font-size: 1.6rem;
        }

        .return:before, .sub > .a:before {
            background-image: url(${Icons24_31});
            content: '';
            display: block;
            width: 2.4rem;
            height: 2.4rem;
            position: absolute;
            top: 0;
            bottom: 0;
        }
        .sub > .a {
            &:before {
                filter: brightness(0) invert(1);
                right: 0;
            }
        }

        .return {
            width: 100%;
            border: none;
            background: none;
            position: relative;
            min-height: 2.4rem;
            display: flex;
            align-items: center;
            padding-left: 5.6rem;
            color: var(--lgray);
            cursor: pointer;
            margin-bottom: 5.4rem;
            &:before {
                transform: rotate(180deg);
                left: 0;
                right: auto;
            }
        }
    }
}

.iconBG32:before  { background-image: url(${Icons24_32}); }

.wrapTitleBlock {
    .BlockTitle {
        font-size: 3rem; 
        line-height: 1.25;
        padding-left: 4.2rem;
        padding-right: 4.2rem;
        text-decoration: none;
        @media (max-width: ${maxCol.sm}) {
            padding-right: 0;
            padding-left: 2.4rem;
        }
    }
    .wrapHeaderIcon {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .more {
        padding-right: 2.4rem;
    }
}
`;

export default GlobalStyles;