import styled from 'styled-components'
import {maxCol} from "../../function/SizeCol";
import Icons24_25 from "../svg/24/Icons24px-25.svg"
import Icons24_34 from "../svg/24/Icons24px-34.svg"
import Icons24_35 from "../svg/24/Icons24px-35.svg"
import Icons24_36 from "../svg/24/Icons24px-36.svg"
import Icons24_37 from "../svg/24/Icons24px-37.svg"


const WrapHeader = styled.header`
    padding-top: 1.8rem;
    .header {
        
        * {
            //height: 100%;
        }
    }
    .wrapLogo {
        //min-width: 26.8rem;
        padding-left: 8.6rem;
        padding-right: 8.6rem;
        @media (max-width:${maxCol.xl}) {
            padding-left: 4.2rem;
            padding-right: 4.2rem;
        }

        @media (max-width:${maxCol.sm}) {
            padding-left: 1rem;
            padding-right: 1rem;
            justify-content: center;
            img {
                max-width: 9rem;
                width: 100%;
            }
        }
    }
    .wrapLogo {
        height: 6.4rem;
        @media (max-width:${maxCol.xl}) {
            height: 5.6rem;
        }
    }
    
    .wrapHeaderIcon {
        width: 6.4rem;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width:${maxCol.xl}) {
            width: 5.6rem;
        } 
    } 

    .iconBG25:before  { background-image: url(${Icons24_25}); }
    .iconBG34:before  { background-image: url(${Icons24_34}); }
    .iconBG35:before  { background-image: url(${Icons24_35}); }
    .iconBG36:before  { background-image: url(${Icons24_36}); }
    .iconBG37:before  { background-image: url(${Icons24_37}); }
 
`;
export default WrapHeader;