import React from 'react';
import ItemSubMenuPop from "./ItemSubMenuPop";

const SubMenuPop = ({menu, menuNumber, triggerMenuPop}) => {
    // console.log('menu >>>>>>', triggerMenuPop);

    return(
        <div className='wrapMenu style-2 subMenu'>
            <ul>
                {menu.nodes.map((item, index) => (
                    <ItemSubMenuPop key={`menu_pop_` + menuNumber + `_` + item.id + `_` + index} item={item} index={index} menuNumber={menuNumber} triggerMenuPop={triggerMenuPop} />
                ))}
            </ul>
        </div>
    )
}
export default SubMenuPop;