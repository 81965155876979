import * as React from "react"
import type { GatsbyBrowser } from "gatsby"
import GlobalFonts from "./src/assets/styles/GlobalFonts"
import GlobalCols from "./src/assets/styles/GlobalCols"
import GlobalStyles from "./src/assets/styles/GlobalStyles"
import Header from "./src/components/Header/Header"
import Footer from "./src/components/Footer/Footer"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({element }) => {

    const class_tempate = (element) => {
        const is404 = element.props.location?.key
        const templateName = element.props.pageContext.templateFix;

        if( templateName ) {
            return templateName;
        } else {
            if ( is404 === 'initial') {
                return 'page404'
            } else {
                return 'customPage'
            }
        }
        // return element.props.pageContext.template?.templateName || 'page404';
    }

    console.log(">>>>>>>>>", class_tempate(element), element.props.pageContext.templateFix )


    return (
        <>
            <GlobalFonts/>
            <GlobalCols/>
            <GlobalStyles/>

            {/*{console.log("element >>>>", element.props.pageContext.isFrontPage)}*/}

            <div className={`body template-${class_tempate(element)} ${class_tempate(element) === 'page404' ? 'colorRevers' : ''}`}>
                <div className="container">
                    <div className="wrapMain border-ver">
                        <Header colorRevers={class_tempate(element) === 'page404' ? 'colorRevers' : 'colorNormal'} />
                        <div className='main'>{element}</div>
                    </div>
                </div>

                { class_tempate(element) === 'page404'  ? '' : ( <Footer data={element.props}/> ) }
            </div>
        </>
    )
}