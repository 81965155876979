import React, {useState} from 'react'
import {graphql, useStaticQuery, Link} from "gatsby"
import WrapHeader from '../../assets/styles/Header'
import Logo from '../../assets/image/Logo.svg'
import LogoWhite from '../../assets/image/Logo-white-header.svg'
// import loadable from '@loadable/component'
import MenuPop from './MenuPop'

const Header = ( props ) => {

    const {allWpMenuItem} = useStaticQuery(graphql`
        query($menu : WpMenuLocationEnum = HEADER_MENU_TOP ) {
            allWpMenuItem(filter: { locations: { eq: $menu }, parentId: {eq: null} }) {
                nodes {
                    id
                    label
                    path
                }
            }
        }
    `);


    // console.log('dataMenuToo >>>>>>>>>>>', dataMenuToo);


    // const LoadComponentMenuPop = loadable(() => import('./MenuPop'))


    // const [first, firstOpen ] = useState(false);
    const [open, setOpen ] = useState(false);

    const clickMenuPop = () => {
        setOpen(!open)
        open ? (
            document.body.classList.remove('ovh')
        ) : ( document.body.classList.add('ovh') )

        // setTimeout(function(){
        //     setOpen(!open)
        // }, 500);

    };

    return (
        <>
            <WrapHeader>
                <div className="header border-hor">
                    <div className="row row-0 row-section">
                        <div className="col col-lg-auto">
                            <Link className="wrapLogo d-flex align-items-center" to='/'>
                                {props.colorRevers === 'colorRevers' ? <img src={LogoWhite} alt=""/> : <img src={Logo} alt=""/>}
                            </Link>
                        </div>
                        <div className="d-none d-sm-block col-sm-auto">
                            <div className="wrapHeaderIcon">
                                <div className='cursorNone btn btn1 btnIcon borderNone'>
                                    <div className='iconBG icon24 iconBG25'></div>
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-lg-block col-lg">
                            <div className="wrapMenu style-1 d-flex align-items-center">
                                <ul className='d-flex align-items-center'>
                                    {allWpMenuItem.nodes.map(item => (
                                        <li key={`top_menu_` + item.id}>
                                            <Link to={item.path}>{item.label}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="wrapHeaderIcon">
                                <Link to="/search" className='btn btn1 btnIcon borderNone'>
                                    <div className='iconBG icon24 iconBG37'></div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="wrapHeaderIcon">
                                <Link to="/cart" className='btn btn1 btnIcon borderNone'>
                                    <div className='iconBG icon24 iconBG36'></div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="wrapHeaderIcon">
                                <Link to="/user" className='btn btn1 btnIcon borderNone'>
                                    <div className='iconBG icon24 iconBG35'></div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="wrapHeaderIcon">
                                <button type="button" aria-labelledby="button-label" className={`btn btn1 btnIcon borderNone` + (open ? ' select' : '') } onClick={() =>clickMenuPop()}>
                                    <span className='iconBG icon24 iconBG34'></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </WrapHeader>
            {/*{(open ? <LoadComponentMenuPop triggerMenuPop={clickMenuPop}   /> : '')}*/}
            {/*{(open ? <MenuPop triggerMenuPop={clickMenuPop}   /> : '')}*/}
            <MenuPop open={open} triggerMenuPop={clickMenuPop}   />
        </>
    )
};

export default Header;