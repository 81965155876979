import styled from 'styled-components'
import {maxCol} from "../../function/SizeCol";
import Icons24_20 from "../../assets/svg/24/Icons24px-20.svg"

const MenuPop = styled.div`
    //position: fixed;
    //top: 0;
    //bottom: 0;
    //left: 0; 
    //right: 0;
    //z-index: 100;
    .wrapMenuPop {
        overflow: hidden;
        background-color: var(--dark);
        color: var(--paper);
        position: fixed;
        top: 0;
        left: -200%;
        bottom: 0;
        max-width: 41.4rem;
        width: 100%; 
        z-index: 2;
        padding-top: 1.8rem;
        padding-bottom: 1.8rem;
        visibility: hidden;
        z-index: 110;
        &.open {
            left: 0;
            visibility: visible;
            & + .shadow {
                visibility: visible;
                opacity: 0.8;
            }
        }
        .border-ver {
            &:after, &:before {
                bottom: -100px;
            }
        }
    }
    .shadow {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0; 
        right: 0;
        z-index: 100;
        background-color: var(--dark);
        cursor: pointer;
        visibility: hidden;
        opacity: 0;
    }
    .wrapIconHide {
        height: 6.4rem;
        width: 6.4rem;
        @media (max-width:${maxCol.xl}) {
            width: 5.6rem;
            height: 5.6rem;
        }
    }
    .wrapLogo {
        padding: 0 3.2rem;
    }
    .iconBG20:before  { background-image: url(${Icons24_20}); }
    .MenuBox {
        padding-left: 3.2rem;
        padding-right: 3.2rem;
        padding-top: 3.2rem;
        padding-bottom: 6.4rem;
        
        @media (max-height: 720px) {
            overflow: hidden;
            overflow-y: auto;
            &.border-hor-bottom:after {
                display: none;
            }
        }
        

        //overflow: hidden;
        //overflow-y: auto;
        
        .wrapMenu {
            padding-top: 3.2rem;
            @media (max-width: 380px) {
                &:first-child {
                    padding-top: 1.2rem;
                }
                li {
                    margin-bottom: 1.2rem;
                }
            }
        }
    }
    
    .wrapMenu {
        li:last-child {
            margin-bottom: 0;
        }
        //&:last-child, &:first-child {
        //    li:last-child {
        //        margin-bottom: 0;
        //    }
        //}
    }
`;
export default MenuPop;