import React from 'react';
import StyleMenuPop from "../../assets/styles/MenuPop";
import Logo from "../../assets/image/Logo-white.svg";
import {graphql, useStaticQuery} from "gatsby";
import SubMenuPop from "./SubMenuPop";


const MenuPop = ({triggerMenuPop, open}) => {
    // console.log('>>>>', triggerMenuPop)
    // const clickMenuPopHide = (d) => {
    //     d = false;
    //     console.log('>>>> click', d)
    // };

    const {HEADER_MENU_POP_1, HEADER_MENU_POP_2, HEADER_MENU_POP_3} = useStaticQuery(graphql`
        query {
            HEADER_MENU_POP_1: allWpMenuItem(
                filter: { locations: { eq: HEADER_MENU_POP_1 }, parentId: { eq: null } }
            ) {
                nodes {
                    id
                    label
                    path
                    childItems {
                        nodes {
                            id
                            label
                            path
                        }
                    }
                }
            }
            HEADER_MENU_POP_2: allWpMenuItem(
                filter: { locations: { eq: HEADER_MENU_POP_2 }, parentId: { eq: null } }
            ) {
                nodes {
                    id
                    label
                    path
                    childItems {
                        nodes {
                            id
                            label
                            path
                        }
                    }
                }
            }
            HEADER_MENU_POP_3: allWpMenuItem(
                filter: { locations: { eq: HEADER_MENU_POP_3 }, parentId: { eq: null } }
            ) {
                nodes {
                    id
                    label
                    path
                    childItems {
                        nodes {
                            id
                            label
                            path
                        }
                    }
                }
            }
        }
    `);




    return (
        <>
            <StyleMenuPop>
                <div className={`wrapMenuPop container anim ${open ? 'open' : 'close'}`}>
                    <div className="h100 border-ver color-white d-flex flex-column">
                        <div className="row row-0 row-section border-hor color-white">
                            <div className="col d-flex align-items-center">
                                <div className="wrapLogo">
                                    <img src={Logo} alt=""/>
                                </div>
                            </div>
                            <div className="col-auto">
                                <div className="wrapIconHide d-flex justify-content-center align-items-center">
                                    <button type="button" aria-labelledby="button-label" className='btn btn1 btnIcon borderNone bgNone' onClick={() =>triggerMenuPop()}>
                                        <div className='iconBG iconWhite icon24 iconBG20'></div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="h100 MenuBox border-hor-bottom color-white d-flex flex-column justify-content-between">

                            <SubMenuPop menu={HEADER_MENU_POP_1} menuNumber={1} triggerMenuPop={triggerMenuPop}  />
                            <SubMenuPop menu={HEADER_MENU_POP_2} menuNumber={2} triggerMenuPop={triggerMenuPop} />
                            <SubMenuPop menu={HEADER_MENU_POP_3} menuNumber={3} triggerMenuPop={triggerMenuPop} />




                            {/*<div className='subMenu'>*/}
                            {/*    {allWpMenuItem.nodes.map(item => (*/}
                            {/*        <li key={`menu_pop_1_` + item.id}>*/}
                            {/*            <Link to={item.path}>{item.label}</Link>*/}
                            {/*        </li>*/}
                            {/*    ))}*/}
                            {/*</div>*/}
                            {/*<div className='subMenu'>*/}
                            {/*    {allWpMenuItem.nodes.map(item => (*/}
                            {/*        <li key={`menu_pop_3_` + item.id}>*/}
                            {/*            <Link to={item.path}>{item.label}</Link>*/}
                            {/*        </li>*/}
                            {/*    ))}*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                <button type="button" aria-labelledby="button-label" onClick={() =>triggerMenuPop()} className="shadow anim"></button>
            </StyleMenuPop>


        </>
    )
}
export default MenuPop;