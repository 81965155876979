import React from "react";
import {Link} from "gatsby";
const FooterMenu = ({menu,name}) => {
    // console.log("menu" , name , menu)
  return (
      <div className={`wrapMenu style-2 menu-${name}`}>
          <ul>
              {
                  menu?.map((item, index) => (
                      <li key={`${name}_${index}`}>
                          <Link to={item.path}>
                              {item.label}
                          </Link>
                      </li>
                  ))
              }
          </ul>
      </div>
  )
}
export default FooterMenu