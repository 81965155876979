export const minCol = {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1440px',
}

export const maxCol = {
    sm: '575px',
    md: '767px',
    lg: '991px',
    xl: '1199px',
    xxl: '1439px',
}