import React from 'react'
import WrapFooter from '../../assets/styles/Footer'
import {graphql, useStaticQuery} from "gatsby";
import loadable from '@loadable/component'
import FooterMenu from './FooterMenu'

import Logo from '../../assets/image/Logo-white.svg'
const Footer = ({data}) => {



    const {post, acfTheme, FOOTER1, FOOTER2, FOOTER3} = useStaticQuery(graphql`
        query QueryAcfThemeAllWpPost {
        
          FOOTER1:allWpMenuItem(filter: { locations: { eq: FOOTER_MENU_POP_1 }, parentId: {eq: null} }) {
            nodes {
              id
              label
              path
            }
          }
          
          FOOTER2:allWpMenuItem(filter: { locations: { eq: FOOTER_MENU_POP_2 }, parentId: {eq: null} }) {
            nodes {
              id
              label
              path
            }
          }
          
          FOOTER3:allWpMenuItem(filter: { locations: { eq: FOOTER_MENU_POP_3 }, parentId: {eq: null} }) {
            nodes {
              id
              label
              path
            }
          }
        
          acfTheme:wp {
            acfThemeGeneralSettings {
              aCFoptionTemplate {
                footerPosts {
                  count
                  link {
                    title
                    url
                  }
                }
                footerDesc {
                  text
                  title
                }
                footerEnd {
                    copyright
                    email
                    facebook 
                    formTitle
                    instagram
                    networkTitle
                    tiktok
                    xTwiter
                }
              }
            }
          }
          
          post:allWpPost(limit: 12) {
            nodes {
              id
              link
              title
              date(formatString: "DD.MM.YYYY")
              categories {
                nodes {
                  link
                  name
                }
              }
              featuredImage {
                node {
                  localFile { 
                    publicURL
                  }
                }
              }
            }
          }
        }
    `);

    const acfThemeBlock = acfTheme.acfThemeGeneralSettings.aCFoptionTemplate;

    // const acfThemeBlock = acf;

    // console.log('>>>', acfThemeBlock.footerEnd)

    const isFrontPage = data.pageContext.isFrontPage;

    const LoadComponentMenuPop = loadable(() => import('./FrontPageGetPost'))

    return (
        <WrapFooter>
            <div className="footer-1 border-hor-top ">

            </div>
            <div className="footer-2">
                <div className="container h100">

                    {(isFrontPage ? <LoadComponentMenuPop post={post} footerPosts={acfThemeBlock.footerPosts} /> : '')}

                    <div className={`WrapDesc WrapFooterItem ${isFrontPage ? '' : 'border-hor-top'}`}>
                        <div className="row">
                            <div className="col-12 col-lg-5 col-xl-auto">
                                <div className='text st1 fw-3'>
                                    {acfThemeBlock?.footerDesc.title}
                                </div>
                            </div>
                            <div className="col-12 col-lg-7 col-xl">
                                <div className='wrapScroll'>
                                    <div className='st5 tc3 desc fw-3' dangerouslySetInnerHTML={{__html: acfThemeBlock?.footerDesc.text}}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='wrapMenuForm WrapFooterItem border-hor-top'>
                        <div className="row row-0 flex-column-reverse flex-xl-row">
                            <div className="col-12 col-xl-8">
                                <div className="wrap">
                                    <div className="row row-0">
                                        <div className="col-12 col-sm">
                                            <FooterMenu name='FOOTER1' menu={FOOTER1.nodes}/>
                                        </div>
                                        <div className="col-12 col-sm">
                                            <FooterMenu name='FOOTER2' menu={FOOTER2.nodes}/>
                                        </div>
                                        <div className="col-12 col-sm">
                                            <FooterMenu name='FOOTER3' menu={FOOTER3.nodes}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-4">
                                <div className="wrap-form  row row-cols-1 row-sm-cols-2 row-cols-xl-1">
                                    <div className="formTitle style-1 st1">
                                        {acfThemeBlock.footerEnd.formTitle}
                                    </div>
                                    <div>
                                        <div className="wrap-input searchBox black">
                                            <input placeholder='your@email.com' type="text"/>
                                            <div className="btn btn2 btnIcon">
                                                <div className="iconBG icon24 iconBG32"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="WrapFooterItem border-hor-top ovh WrapFooterItemEnd">
                        <div className="row flex-column-reverse flex-sm-row">
                            <div className="col-12 col-sm-6 col-lg-8">
                                <div className='copyright'>
                                    <img className="logo" src={Logo} alt=""/>
                                    <p className='tc3'>{acfThemeBlock.footerEnd.copyright}</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                            <p className='tc3 st1'>{acfThemeBlock.footerEnd.networkTitle}</p>
                                <div className="wrap-network-icon d-inline-flex">
                                    <a href={acfThemeBlock.footerEnd.facebook} className='btn btn1 btnIcon' target="_blank" rel="noreferrer" ><span className='iconBG icon24 iconBG21 iconWhite'></span></a>
                                    <a href={acfThemeBlock.footerEnd.xTwiter} className='btn btn1 btnIcon' target="_blank" rel="noreferrer" ><span className='iconBG icon24 iconBG22 iconWhite'></span></a>
                                    <a href={acfThemeBlock.footerEnd.instagram} className='btn btn1 btnIcon' target="_blank" rel="noreferrer" ><span className='iconBG icon24 iconBG23 iconWhite'></span></a>
                                    <a href={acfThemeBlock.footerEnd.tiktok} className='btn btn1 btnIcon' target="_blank" rel="noreferrer" ><span className='iconBG icon24 iconBG24 iconWhite'></span></a>
                                    <a href={`mailto:${acfThemeBlock.footerEnd.email}`} className='btn btn1 btnIcon'><span className='iconBG icon24 icons_email'></span></a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </WrapFooter>
    )
}
export default Footer;




